import { ReactNode } from 'react';

import { Box } from '../Box';
import { Select, SelectProps } from '../Select';
import { ErrorMessage } from '../TextField';

import {
  SelectFieldCustomTrigger,
  SelectFieldCustomTriggerProps,
} from './SelectFieldCustomTrigger';

export type SelectFieldProps<T extends string> = SelectProps<T> &
  SelectFieldCustomTriggerProps & {
    dropDownWidth?: number | string | 'parent';
    error?: ReactNode;
    maxDropDownHeight?: number | string;
    optionalLabel?: string;
  };

export const SelectField = <T extends string>({
  label,
  children,
  dropDownWidth,
  style,
  maxDropDownHeight,
  optionalLabel,
  suffix,
  error,
  ...props
}: SelectFieldProps<T>) => {
  return (
    <Box flexDirection="column" style={{ width: '100%' }}>
      <Select
        {...props}
        style={{
          ...style,
          ...(dropDownWidth
            ? {
                width:
                  dropDownWidth === 'parent'
                    ? 'var(--radix-dropdown-menu-trigger-width)'
                    : dropDownWidth,
                maxHeight: maxDropDownHeight,
                '--radix-popper-transform-origin':
                  dropDownWidth === 'parent' ? '50% 0' : undefined,
              }
            : {}),
        }}
      >
        <SelectFieldCustomTrigger
          label={label}
          disabled={props.disabled}
          optionalLabel={optionalLabel}
          suffix={suffix}
          error={error}
        >
          {children}
        </SelectFieldCustomTrigger>
      </Select>
      {error ? <ErrorMessage message={error} /> : null}
    </Box>
  );
};
