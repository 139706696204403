import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink,
  from,
} from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { setContext } from '@apollo/client/link/context';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth } from '@clerk/nextjs';
import { createClient } from 'graphql-ws';
import { PropsWithChildren, useMemo } from 'react';

const GRAPHQL_API_URL = process.env.NEXT_PUBLIC_FINARY_GRAPHQL_API as string;
const GRAPHQL_API_WS = process.env.NEXT_PUBLIC_FINARY_GRAPHQL_WS as string;

const IS_DEV_ENV = process.env.NODE_ENV === 'development';

if (IS_DEV_ENV) {
  loadDevMessages();
  loadErrorMessages();
}

const cache = new InMemoryCache();

export const ApolloProviderWrapper = ({ children }: PropsWithChildren) => {
  const { getToken } = useAuth();

  const client = useMemo(() => {
    const removeTypenameLink = removeTypenameFromVariables();

    const authLink = setContext(async (_, { headers }) => {
      const token = await getToken();

      if (!token) {
        return headers;
      }

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    const httpLink = new HttpLink({
      uri: GRAPHQL_API_URL,
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: GRAPHQL_API_WS,
        lazyCloseTimeout: 3000,
        lazy: true,
        keepAlive: 5000,
        shouldRetry: () => true,
        connectionParams: async () => {
          const token = await getToken();

          if (token) {
            return {
              authorization: `Bearer ${token}`,
            };
          }
        },
        on: {
          error: (error) => {
            if (IS_DEV_ENV) {
              // eslint-disable-next-line no-console
              console.log('error', JSON.stringify(error, null, 2));
            }
          },
        },
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);

        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      authLink.concat(httpLink)
    );

    return new ApolloClient({
      link: from([removeTypenameLink, splitLink]),
      cache,
    });
  }, [getToken]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
