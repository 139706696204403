import classNames from 'classnames';
import {
  ComponentProps,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
} from 'react';

import { useCombinedRefs } from '../../hooks/useCombinedRefs';
import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';
import { ErrorMessage } from '../TextField';
import { Tooltip, TooltipProps } from '../Tooltip';
import { Typography } from '../Typography';

import {
  checkboxCheckContainerStyles,
  checkboxCheckIconStyles,
  checkboxContainerCenterStyles,
  checkboxContainerStartStyles,
  checkboxContainerStyles,
  checkboxHyphenIconStyles,
  checkboxInputStyles,
  checkboxLabelStyles,
  checkboxTooltipButtonStyles,
  checkboxTooltipIconStyles,
} from './Checkbox.css';

export type CheckboxProps = Omit<ComponentProps<'input'>, 'size'> & {
  name: string;
  containerBoxProps?: BoxProps;
  error?: ReactNode;
  indeterminate?: boolean;
  label?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  tooltip?: Pick<TooltipProps, 'title' | 'description' | 'size' | 'usePortal'>;
  variant?: 'start' | 'center';
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      variant = 'center',
      size = 'medium',
      error,
      label,
      className,
      tooltip,
      style,
      required = false,
      indeterminate,
      containerBoxProps,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(ref, innerRef);

    useEffect(() => {
      if (innerRef.current) {
        innerRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate]);

    return (
      <Box flexDirection="column" style={style}>
        <Box
          as="label"
          className={classNames(
            checkboxContainerStyles,
            variant === 'center'
              ? checkboxContainerCenterStyles
              : checkboxContainerStartStyles,
            className
          )}
          data-disabled={props.disabled}
          gap="s3"
          {...containerBoxProps}
        >
          <Box>
            <input
              {...props}
              ref={combinedRef}
              id={props.name}
              type="checkbox"
              data-error={!!error}
              required={required}
              className={checkboxInputStyles}
            />
            <Box
              className={checkboxCheckContainerStyles}
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                name="check"
                size="small"
                className={checkboxCheckIconStyles}
              />
              <Icon
                name="hyphen"
                size="small"
                className={checkboxHyphenIconStyles}
              />
            </Box>
          </Box>

          {label ? (
            <Typography
              as="label"
              htmlFor={props.name}
              className={checkboxLabelStyles({ variant, size })}
              color="tertiary"
            >
              {label}
              {tooltip ? (
                <Tooltip
                  size={tooltip.size || 'small'}
                  title={tooltip.title}
                  description={tooltip.description}
                  usePortal={tooltip.usePortal}
                  contentProps={{
                    side: 'top',
                    sideOffset: 4,
                    style: { maxWidth: 220 },
                  }}
                >
                  <button
                    type="button"
                    className={checkboxTooltipButtonStyles({ variant, size })}
                  >
                    <Icon
                      name="infoCircle"
                      size="small"
                      color={props.disabled ? 'disabled' : 'tertiary'}
                      className={checkboxTooltipIconStyles({ variant })}
                    />
                  </button>
                </Tooltip>
              ) : null}
            </Typography>
          ) : null}
        </Box>
        {error ? <ErrorMessage message={error} /> : null}
      </Box>
    );
  }
);

Checkbox.displayName = 'Checkbox';
