import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '../Box';
import { Typography } from '../Typography';

import { splitFieldStyles } from './SplitField.css';
import {
  ErrorMessage,
  FieldErrorMessage,
  HintMessage,
  SuccessMessage,
  TextFieldProps,
  WarningMessage,
} from './TextField';

type SplitFieldProps = Omit<
  TextFieldProps,
  'prefix' | 'suffix' | 'optionalLabel' | 'placeholder' | 'ref' | 'hint'
> & {
  split: number | number[];
  autoComplete?: 'one-time-code' | undefined;
  autoFocus?: boolean;
  autoSubmit?: boolean;
  hint?: React.ReactNode;
  shouldValidate?: boolean;
  subLabel?: React.ReactNode;
};

/**
 * A split field is spread across multiple visible inputs while being tied
 * to a single form hidden input. Typically used for confirmation codes
 */
export const SplitField = React.forwardRef<HTMLInputElement, SplitFieldProps>(
  (
    {
      label,
      subLabel,
      className,
      hint,
      success,
      warning,
      error,
      split,
      autoFocus,
      type,
      style,
      shouldValidate,
      autoSubmit,
      ...props
    },
    ref
  ) => {
    const form = useFormContext();
    const fieldError = form.formState.errors[props.name];

    // Move focus to the next input and update the hidden field
    const processTotalValue = (currentTarget: HTMLInputElement) => {
      // Compute the value for the hidden input
      let value = '';
      const hiddenInput =
        currentTarget.parentElement?.parentElement?.querySelector(
          'input[type=hidden]'
        );
      const inputs =
        currentTarget.parentElement?.parentElement?.querySelectorAll(
          'input:not([type=hidden])'
        ) as NodeListOf<HTMLInputElement>;
      if (inputs) {
        // We stop at the first input that does not have a value
        Array.from(inputs).every((input) => {
          value += input.value;
          return input.value;
        });
        if (hiddenInput instanceof HTMLInputElement) {
          hiddenInput.value = value;
          form.setValue(props.name, value, {
            shouldValidate,
          });
        }
      }
      // When the input already has a value, we move the focus to the next input
      if (currentTarget.value.length) {
        const next = getNextInput(currentTarget);
        if (next instanceof HTMLInputElement) {
          next.focus();
        }
      }
      // When the input is full, auto-submit if needed
      const totalExpectedLength =
        typeof split === 'number' ? split : split.reduce((a, b) => a + b, 0);
      if (autoSubmit && value.length === totalExpectedLength) {
        const form = currentTarget.closest('form');
        const submit = form?.querySelector(
          'button:not([type=reset],[type=button])'
        ) as HTMLButtonElement;
        setTimeout(() => {
          submit?.click();
        });
      }
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
      event
    ) => {
      const currentTarget = event.currentTarget;
      processTotalValue(currentTarget);
    };

    // Focus Pocus: focus the first empty exploded input if empty,
    // select the current cell value otherwise
    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
      const currentTarget = event.currentTarget;
      if (!currentTarget.value) {
        const inputs =
          currentTarget.parentElement?.parentElement?.querySelectorAll(
            'input:not([type=hidden])'
          ) as NodeListOf<HTMLInputElement>;
        if (inputs) {
          Array.from(inputs).some((input) => {
            const isEmpty = !input.value;
            if (isEmpty) {
              input.focus();
            }
            return isEmpty;
          });
        }
      } else {
        // In case of a click we can select right away
        currentTarget.select();
        // We need to wait for the cursor to be set before selecting the input
        setTimeout(() => currentTarget.select());
      }
    };

    // Because inputting the same value again doesn't trigger a change event,
    // and because we don't want to fire twice an event in different fields,
    // we use the keyup event to move the focus to the next field
    const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      const currentTarget = event.currentTarget;
      const currentValue = currentTarget.value;
      let next: Element | null = null;
      switch (event.key.toLowerCase()) {
        case `${currentValue}`:
          next = getNextInput(currentTarget);
          break;
        case 'backspace':
        case 'arrowleft':
        case 'arrowright':
          return;
      }
      if (next instanceof HTMLInputElement) {
        next.focus();
      }
    };

    // Handle keyboard navigation: it feels expected that arrow keys would
    // move focus between inputs
    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      const currentTarget = event.currentTarget;
      let next: Element | null = null;
      switch (event.key.toLowerCase()) {
        case 'arrowleft':
          if (currentTarget.selectionStart === 0) {
            next = getPreviousInput(currentTarget);
          }
          break;
        case 'arrowright':
          if (currentTarget.selectionEnd === 1) {
            next = getNextInput(currentTarget);
          }
          break;
        case 'backspace':
          if (!currentTarget.value.length) {
            next = getPreviousInput(currentTarget);
            if (next instanceof HTMLInputElement) {
              next.value = '';
            }
          }
          break;
      }
      if (next instanceof HTMLInputElement) {
        next.focus();
      }
    };

    const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      event.preventDefault();
      const pastedText = event.clipboardData.getData('text');
      if (pastedText) {
        const inputs =
          event.currentTarget.parentElement?.parentElement?.querySelectorAll(
            'input:not([type=hidden])'
          ) as NodeListOf<HTMLInputElement>;
        if (inputs) {
          const inputsArray = Array.from(inputs);
          const beforeLastInput = inputsArray[inputsArray.length - 2];
          inputsArray.forEach((input, index) => {
            input.value = pastedText[index] ?? '';
          });
          if (beforeLastInput instanceof HTMLInputElement) {
            processTotalValue(beforeLastInput);
          }
        }
      }
    };

    const inputGroups =
      typeof split === 'number'
        ? [[...new Array(split)]]
        : split.map((amount) => [...new Array(amount)]);

    return (
      <Box flexDirection="column" style={style} className={className}>
        <Box flexDirection="column" gap="s7" data-disabled={props.disabled}>
          {label || subLabel ? (
            <Box flexDirection="column" gap="s3">
              {label ? (
                <Typography
                  as="label"
                  htmlFor={props.name}
                  variant="displaySmall"
                  color="primary"
                >
                  {label}
                </Typography>
              ) : null}
              {subLabel ? (
                <Typography variant="bodyMedium" color="tertiary">
                  {subLabel}
                </Typography>
              ) : null}
            </Box>
          ) : null}
          <Box flexDirection="row" alignItems="center">
            <Box
              flexDirection="row"
              alignItems="center"
              gap={{ mobile: 's4', tablet: 's8' }}
            >
              <input ref={ref} data-lpignore="true" type="hidden" {...props} />
              {inputGroups.map((group, index) => {
                return (
                  <Box key={index} gap={{ mobile: 's3', tablet: 's5' }}>
                    {group.map((_, innerIndex) => (
                      <Typography
                        key={innerIndex}
                        as="input"
                        data-testid={`split-field-${index}-${innerIndex}`}
                        variant="headingLarge"
                        paddingVertical="s6"
                        paddingHorizontal={{ tablet: 's5' }}
                        flex={1}
                        style={{
                          width: `calc(100% / ${inputGroups.flat().length})`,
                        }}
                        autoFocus={autoFocus && index === 0}
                        data-lpignore="true"
                        maxLength={1}
                        type="text"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onKeyUp={handleKeyUp}
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}
                        textAlign="center"
                        borderRadius="small"
                        className={splitFieldStyles.input({
                          error: !!(fieldError || error),
                        })}
                        {...(type === 'number' ? { inputMode: 'numeric' } : {})}
                      />
                    ))}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        {hint && !success && !warning && !(fieldError || error) ? (
          <HintMessage message={hint} />
        ) : null}
        {success && !warning && !(fieldError || error) ? (
          <SuccessMessage message={success} />
        ) : null}
        {warning && !(fieldError || error) ? (
          <WarningMessage message={warning} />
        ) : null}
        {fieldError ? (
          <FieldErrorMessage error={fieldError} />
        ) : error ? (
          <ErrorMessage message={error} />
        ) : null}
      </Box>
    );
  }
);

SplitField.displayName = 'SplitField';

const getPreviousInput = (currentTarget: HTMLInputElement) => {
  let next = currentTarget.previousElementSibling;
  if (!(next instanceof HTMLInputElement)) {
    const nextGroup = currentTarget.parentElement?.previousElementSibling;
    const nextGroupInput = nextGroup?.querySelector(
      'input:not([type=hidden]):last-of-type'
    );
    if (nextGroupInput instanceof HTMLInputElement) {
      next = nextGroupInput;
    }
  }
  return next instanceof HTMLInputElement ? next : null;
};

const getNextInput = (currentTarget: HTMLInputElement) => {
  let next = currentTarget.nextElementSibling;
  if (!(next instanceof HTMLInputElement)) {
    const nextGroup = currentTarget.parentElement?.nextElementSibling;
    const nextGroupInput = nextGroup?.querySelector('input');
    if (nextGroupInput instanceof HTMLInputElement) {
      next = nextGroupInput;
    }
  }
  return next instanceof HTMLInputElement ? next : null;
};
