import Script from 'next/script';
import { useUIState } from 'ui/providers/UIProvider';

import { inPlaywrightTest } from '/utils/test';
import { useIsFromMobileNativeView } from '/hooks/useIsFromMobileNativeView';

export const AxeptioScript = () => {
  const { locale } = useUIState();
  const cookieLocale = locale === 'fr' ? 'fr' : 'en';
  const cookieDomain =
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '.finary.com' : null;

  const isFromMobileNativeView = useIsFromMobileNativeView();

  if (inPlaywrightTest() || isFromMobileNativeView) {
    return null;
  }

  const axeptioSettings = {
    clientId: process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID,
    cookiesVersion: `finary-${cookieLocale}`,
    userCookiesDomain: cookieDomain,
    googleConsentMode: {
      default: {
        analytics_storage: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        wait_for_update: 500,
      },
    },
  };

  return (
    <Script
      id="axeptio"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
window.axeptioSettings = ${JSON.stringify(axeptioSettings)};
(function(d, s) {
  var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
  e.async = true; e.src = "https://static.axept.io/sdk.js";
  t.parentNode.insertBefore(e, t);
})(document, "script");
`,
      }}
    />
  );
};
