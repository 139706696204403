import { Animation } from '../../styles/animations.css';
import { Sprinkles } from '../../styles/sprinkles.css';
import { Box } from '../Box';
import { Icon, IconName } from '../Icon';
import { ImageWrapper } from '../ImageWrapper';
import { Skeleton } from '../Skeleton';

export const DEFAULT_ASSET_LOGO =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAt1BMVEVHcEwtLjMvLzMvLy8vLy8uLjMuLzMtLjMvLzctLzMuLjMvLzItLzIuLjIuLjIuLjMuLjMuLjMtLzQuLjItLTIvLzItLzIuLjItLjIuLjMuMDMuLzMvLzMuMDMvLz8uLjMuLjEtLjIuMDMuLzIuMDQtLjMuMDQsLzMuLjEtLzUuLzNucnpiZm1SVVs+QEVmanEyNDhSVFsyMzg2NzxWWWA2ODw6O0BWWV9qbnZKTFJmanJGSU5eYmnHAHKyAAAAKnRSTlMA30AQIN+Q7yCA72Bgf8+Pn69wv29wcN/fz9+gUJ8QoI/Pz7Cvz79Qn2Coud0aAAABpklEQVR4Xq3U53LbMBAE4AUFEqCoasWWZStxXNJWzS097/9cGWcywozQxKO//8u7w+wQMbYZT0dzkpyPpvXSopViPOcBNSlwpHdDwyA1sLK4Y8YWGW8Mk9QAKXbGLGVl4x1zhoj3PNIQQZ95tAkCPrCFUWy+fIchWxofvD9bO4MDa9iasXAUBWbYG9CRHGEVRUzPNVCm9l5QtsKA7LaCopgBgIIdFAAm7KCKX7Dd7FaH6FGAZcj9w8q3ps/iUzC/XgU80/cFNQMeViFP9FWY0vc1mN8w4BbX9H0P5dePDFjgnL51aH4wTwUGeOlfmy0jQIHX/kC2ib+ZgPN8E7eMU7jON/GecQtMs018ZsItqlwTd09MqDHINPHHN6YsodNN3KXz1Ah1eT/9z89HJpUAKnbw9jV+qjAUK/GipliDFz1DIaPRbYUK6LRCqfHfqfQF9mYUKOFowRF9DUdyxAkcSaFrHLhiK5fwjNjCAgFXHea3bGSFiFPDPPZPEKVLZn3USGlK2XhHV/1EvO4hTzelMO4Ul94lF1WBVvSyvru5+Be9uasai4i/r4YKvDJvz0kAAAAASUVORK5CYII=';

const LOGO_SIZE = {
  xxxxlarge: 106,
  xxxlarge: 72,
  xxlarge: 62,
  xlarge: 40,
  large: 32,
  medium: 24,
  small: 16,
} as const;

const MAP_TO_ICON_SIZE = {
  xxxxlarge: 'large',
  xxxlarge: 'large',
  xxlarge: 'large',
  xlarge: 'medium',
  large: 'small',
  medium: 'xsmall',
  small: 'xxsmall',
} as const;

type AssetLogoBaseProps = {
  noFallback?: boolean;
  size?: keyof typeof LOGO_SIZE;
  style?: React.CSSProperties;
};

export type AssetLogoImgProps = AssetLogoBaseProps & {
  src: string | null | undefined;
  icon?: never;
  iconAnimation?: never;
  iconBackgroundColor?: never;
  iconColor?: never;
};

export type AssetLogoIconProps = AssetLogoBaseProps & {
  icon?: IconName;
  iconAnimation?: Animation;
  iconBackgroundColor?: string;
  iconColor?: Sprinkles['color'];
  src?: never;
};

export type AssetLogoProps = AssetLogoImgProps | AssetLogoIconProps;

export const AssetLogo = ({
  src,
  size = 'large',
  noFallback,
  icon,
  iconColor,
  iconBackgroundColor,
  iconAnimation,
  style,
}: AssetLogoProps) => {
  const assetLogoSize = LOGO_SIZE[size];

  if (icon) {
    return (
      <Box
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
        background="tertiary"
        style={{
          width: assetLogoSize,
          height: assetLogoSize,
          minWidth: assetLogoSize,
          minHeight: assetLogoSize,
          maxWidth: assetLogoSize,
          maxHeight: assetLogoSize,
          background: iconBackgroundColor,
          ...style,
        }}
      >
        <Icon
          name={icon}
          size={MAP_TO_ICON_SIZE[size]}
          color={iconColor}
          animation={iconAnimation}
        />
      </Box>
    );
  }

  return (
    <ImageWrapper
      src={src}
      variant="keepRatio"
      width={assetLogoSize}
      height={assetLogoSize}
      noFallback={noFallback}
      fallback={DEFAULT_ASSET_LOGO}
      style={{
        borderRadius: assetLogoSize,
        ...style,
      }}
    />
  );
};

export const AssetLogoLoader = ({
  size = 'large',
}: Pick<AssetLogoProps, 'size'>) => {
  return (
    <Skeleton
      width={LOGO_SIZE[size]}
      height={LOGO_SIZE[size]}
      radius={LOGO_SIZE[size] / 2}
    />
  );
};
