import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import { Box, BoxProps } from '../Box';
import { ErrorMessage } from '../TextField';
import { Typography } from '../Typography';
import 'react-phone-input-2/lib/style.css';
import './PhoneNumberField.css';

import { phoneNumberFieldStyles } from './PhoneNumberField.css';

export type PhoneNumberFieldProps = {
  name: string;
  error?: ReactNode;
  label?: ReactNode;
} & BoxProps;

export const PhoneNumberField = forwardRef<
  HTMLInputElement,
  PhoneNumberFieldProps
>(({ name, error, label, className, ...props }, ref) => {
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  return (
    <Box flexDirection="column" {...props}>
      <Box
        flexDirection="column"
        className={classNames(
          phoneNumberFieldStyles.container({ error: !!error }),
          className
        )}
      >
        <Typography
          as="label"
          htmlFor={name}
          className={phoneNumberFieldStyles.label}
        >
          {label}
        </Typography>
        <PhoneInput
          inputProps={{
            ref: ref,
            id: name,
          }}
          country="fr"
          placeholder="+33 6 31 07 34 26"
          preferredCountries={['fr', 'gb', 'us']}
          value={value}
          onChange={(value) => setValue(name, value, { shouldValidate: true })}
        />
      </Box>
      {error ? <ErrorMessage message={error} /> : null}
    </Box>
  );
});

PhoneNumberField.displayName = 'PhoneNumberField';
