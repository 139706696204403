import { ReactNode, forwardRef, useId } from 'react';

import { Box, BoxProps } from '../Box';
import { InfoCircleTooltip } from '../InfoCircleTooltip';
import { Typography } from '../Typography';

import { Radio, RadioProps } from './Radio';

export type RadioLabelFieldProps = RadioProps & {
  boxProps?: BoxProps<'label'>;
  label?: ReactNode;
  tooltip?: React.ReactNode;
};

export const RadioLabelField = forwardRef<
  HTMLInputElement,
  RadioLabelFieldProps
>(({ boxProps, label, tooltip, ...props }, ref) => {
  const id = useId();

  return (
    <Box
      as="label"
      flexDirection="row"
      alignItems="center"
      gap="s3"
      htmlFor={id}
      {...boxProps}
    >
      <Radio ref={ref} id={id} {...props} />
      <Typography variant="bodyMedium">{label}</Typography>
      {tooltip ? (
        <InfoCircleTooltip
          description={tooltip}
          size="medium"
          iconSize="small"
          color="secondary"
          contentProps={{ style: { maxWidth: 400 } }}
        />
      ) : null}
    </Box>
  );
});

RadioLabelField.displayName = 'RadioLabelField';
