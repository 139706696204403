import classNames from 'classnames';
import { ReactNode, forwardRef, useId } from 'react';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

import {
  selectFieldButton,
  selectFieldButtonIcon,
  selectFieldLabelActiveStyles,
  selectFieldLabelStyles,
  selectFieldTriggerContainer,
} from './SelectField.css';

export type SelectFieldCustomTriggerProps = {
  children: ReactNode;
  disabled?: boolean;
  error?: ReactNode;
  label?: string;
  optionalLabel?: string;
  suffix?: ReactNode;
};

export const SelectFieldCustomTrigger = forwardRef<
  HTMLButtonElement,
  SelectFieldCustomTriggerProps
>(
  (
    {
      label,
      children,
      disabled,
      optionalLabel,
      suffix,
      error,
      ...props
    }: SelectFieldCustomTriggerProps,
    ref
  ) => {
    const carretDownKey = useId();

    return (
      <Box
        data-disabled={disabled}
        data-error={error ? 'true' : undefined}
        className={selectFieldTriggerContainer}
      >
        <Typography
          as="label"
          className={classNames(
            selectFieldLabelStyles,
            children ? selectFieldLabelActiveStyles : undefined
          )}
          color="tertiary"
        >
          {label}
          {optionalLabel ? (
            <Typography variant="bodySmall" color="tertiary">
              {optionalLabel}
            </Typography>
          ) : null}
        </Typography>
        <Box
          ref={ref}
          className={selectFieldButton}
          as="button"
          type="button"
          {...props}
        >
          <Box flexGrow={1}>{children}</Box>
          {suffix ?? (
            <Icon
              key={carretDownKey} // to prevent transitioning from custom suffix to default caret
              name="caretDown"
              size="small"
              color={disabled ? 'disabled' : 'tertiary'}
              className={selectFieldButtonIcon}
            />
          )}
        </Box>
      </Box>
    );
  }
);

SelectFieldCustomTrigger.displayName = 'SelectFieldCustomTrigger';
