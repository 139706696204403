import * as React from 'react';

import { radioStyle } from './Radio.css';

export type RadioProps = React.ComponentProps<'input'> & {
  error?: boolean;
};

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ error, ...props }, ref) => {
    return (
      <input
        {...props}
        ref={ref}
        type="radio"
        className={radioStyle({ error })}
      />
    );
  }
);

Radio.displayName = 'Radio';
