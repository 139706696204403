import { ReactNode, forwardRef } from 'react';

import { Box } from '../Box';
import { ErrorMessage } from '../TextField';

import { RadioProps } from './Radio';
import { RadioLabelField } from './RadioLabelField';

export type RadioFieldsGroupProps = Omit<RadioProps, 'value' | 'error'> & {
  options: {
    value: RadioProps['value'];
    label?: ReactNode;
    tooltip?: ReactNode;
  }[];
  error?: ReactNode;
};

export const RadioFieldsGroup = forwardRef<
  HTMLInputElement,
  RadioFieldsGroupProps
>(({ options, error, ...props }, ref) => {
  return (
    <Box flexDirection="column">
      <Box flexDirection="column" gap="s4">
        {options.map(({ label, value, tooltip }, i) => (
          <RadioLabelField
            key={i}
            ref={ref}
            label={label}
            value={value}
            error={!!error}
            tooltip={tooltip}
            {...props}
          />
        ))}
      </Box>
      {error ? <ErrorMessage message={error} /> : null}
    </Box>
  );
});

RadioFieldsGroup.displayName = 'RadioFieldsGroup';
